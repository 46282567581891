import React, {useEffect, useState} from "react";
import wiss from './../assets/wiss.png';
import wissresume from './../assets/wissile_sogoyou_resume.pdf';
// import useAnalyticsEventTracker from '../helpers/useAnalyticsEventTracker';
import {FiArrowUpRight} from 'react-icons/fi';
import { MdDownloadForOffline } from "react-icons/md";
import {FaLinkedin, FaGithub } from 'react-icons/fa';
import './../css/resume.scss';
 
function Resume() {
    

    // const gaEventTracker = useAnalyticsEventTracker('Resume');

    // const leftLinkAbout = document.querySelector('.scroll-content-left .anchor-link span#link-about');
    // const leftLinkExperience = document.querySelector('.scroll-content-left .anchor-link span#link-experience');
    // const leftLinkPortfolio = document.querySelector('.scroll-content-left .anchor-link span#link-portfolio');

    // const linkAboutContent = document.querySelector('.scroll-content-right span.link-about');
    // const linkExperinceContent = document.querySelector('.scroll-content-right span.link-experience');
    // const linkPortfolioContent = document.querySelector('.scroll-content-right span.link-portfolio');
    


    useEffect(() => {

if(window.innerWidth > 768){


    // navigation
const navigation = document.querySelector('.anchor-link');


const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting === true) {
      const uid = entry.target.dataset.sectionName;

      const active = navigation.querySelector('span.active');
      if (active !== null) {
        active.classList.remove('active');
      }

      const button = navigation.querySelector('[data-link="'+uid+'"]');
      if (button !== null) {
        button.classList.add('active');
      }
    }
  })
}, {
    root: document, 
    rootMargin: "-50% -50%",
    threshold: 0
});

const stickySection = document.querySelectorAll('section[data-section-name]');
stickySection.forEach((elm, idx) => {
  observer.observe(elm);
});

// smooth anchor move
const buttons = navigation ? navigation.querySelectorAll('span') : null;
buttons && buttons.forEach((elm, idx) => {
  elm.addEventListener('click', (e) => {
    e.preventDefault();
    const uid = elm.getAttribute('data-link');
    if (uid){
        document.querySelector('section[data-section-name="'+uid+'"]') && 
        document.querySelector('section[data-section-name="'+uid+'"]').scrollIntoView({behavior: 'smooth',block: "start", inline: "nearest"});
    }
  })
});





   

      

    let spotlight = document.querySelector("#spotlight");
    let spotlight_child = document.querySelector("#spotlight-child");
    let mainDiv = document.querySelector("#main") ? document.querySelector("#main") : null;
    if(mainDiv){
        mainDiv.addEventListener("mousemove", moveSpotlight);
        mainDiv.addEventListener("touchmove", moveSpotlight);
    }
    
    function moveSpotlight(e) {
        let pos, x, y;
        e.preventDefault();
        // x = e.clientX - 300;
        // y = e.clientY - 350;
        x = e.clientX;
        y = e.clientY;
        spotlight.style.left = x + "px";
        spotlight.style.top = y + "px";
        spotlight_child.style.left = x + "px";
        spotlight_child.style.top = y + "px";
    }

}

}, []);

  return (
    <>
       <div className="main" id="main">
            <div className="cursor-box" id="spotlight">
                <div className="cursor-box-child" id="spotlight-child"></div>
            </div>
      
                        <div className="container outer_div">
                            <div className="scroll-content-left">
                                <div>
                                    <div className="profile_picture"><img src={wiss} alt="wissile_profile_picture"/></div>
                                    <h3 className="title-3">Wissile Sogoyou</h3>
                                    <h5 className="title-5">Principal, Software Engineer</h5>
                                    <p className="content">Bringing a unique blend of design and engineering expertise, I bridge the communication gap between teams by translating complex concepts into clear language.</p>
                                   
                                   
                                    {/* <div className="anchor-link">
                                        <span id="link-about" onClick={(e)=>{ handleClick(linkAboutContent, e)  }} className="active">About</span>
                                        <span id="link-experience" onClick={(e)=>{ handleClick(linkExperinceContent, e) }} className="">Experience</span>
                                         <span id="link-portfolio" onClick={(e)=>{ handleClick(linkPortfolioContent, e) }} className="">Skills</span> 
                                        <span id="link-portfolio" onClick={(e)=>{ handleClick(linkPortfolioContent, e) }} className="">Portfolio</span>
                                    </div> */}
                                    <nav className="anchor-link">
                                        <span id="link-about" data-link="section1" className="active">About</span>
                                        <span id="link-experience" data-link="section2" className="">Skills</span>
                                        <span id="link-portfolio" data-link="section3" className="">Experience</span>
                                    </nav>
                                   
                                    <div className="social-links">
                                        <a href="https://github.com/wissile" className="button">Github <FaGithub size={25}/></a>
                                        <a href="https://www.linkedin.com/in/wissile/" className="button">LinkedIn <FaLinkedin size={25}/></a>
                                    </div>

                                </div>
                            </div>
                            <div className="scroll-content-right">

                                <div>

                                <div className="mobile-intro"><div className="profile_picture"><img src={wiss} alt="wissile_profile_picture"/></div>
                                        <h3 className="title-3">Wissile Sogoyou</h3>
                                        <h5 className="title-5">Principal, Software Engineer</h5>
                                        <p className="content">Bringing a unique blend of design and engineering expertise, I bridge the communication gap between teams by translating complex concepts into clear language.</p>
                                        <div className="social-links">
                                            <a href={wissresume} className="button large">Download Resume <MdDownloadForOffline size={25}/></a>
                                        </div>
                                    </div>

                                <section id="section1" className="section1 about" data-section-name="section1">

                                   

                                    <h2>Intro</h2>
                                    <div className="content">
                                    Highly accomplished design-minded engineer with 10+ years of software development experience, proven leadership of distributed teams, and a passion for building scalable software. I excel in cross-team communication, process improvement, and delivering exceptional customer experiences using agile methodologies.
                                    </div>
                                    <div className="social-links">
                                        <a href={wissresume} className="button large">Download Resume <MdDownloadForOffline size={25}/></a>
                                    </div>

                                   


                                    


                                </section>

                                {/* <section>
                                    <div className="skills-container">
                                        <div className="skills-box">
                                            <FaGithub size={45}/>
                                            <h5>Team lead</h5>
                                            <p>Leading a team of engioneers for large complex project</p>
                                        </div>
                                        <div className="skills-box">
                                            <FaGithub size={45}/>
                                            <h5>Team lead</h5>
                                            <p>Leading a team of engioneers for large complex project</p>
                                        </div>
                                    </div>
                                </section> */}
                                
                                <section id="section2" className="section2 experience" data-section-name="section2">
                                {/* <div className="resumespacer"></div> */}
                                    <h2>Skills</h2>
                                    <span className="link-skills"></span>
                                    <div className="insights-container">

                                        {/* <div className="circle-chart-container">
                                            <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="54,100" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                            </svg>
                                            <span className="circle-chart-percentage circle-chart-one-percentage">29.1%</span>
                                            <svg className="circle-chart circle-chart-two" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                <circle stroke="#efefef" strokeWidth="0" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                <circle className="circle-chart-circle" stroke="#5292ac" strokeWidth="3" strokeDasharray="85,200" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                            </svg>
                                            <span className="circle-chart-percentage circle-chart-two-percentage">54.9%</span>
                                        </div> */}

                                        <div className="row middle">
                                            <div className="col-xs-3">
                                                <div className="circle-chart-container small">
                                                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                        <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                        <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="95,200" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    </svg>
                                                    <span className="circle-chart-percentage circle-chart-one-percentage">98%</span>
                                                </div>
                                                <div className="row circle-chart-content">HTML & CSS</div>
                                            </div>
                                            <div className="col-xs-3">
                                                <div className="circle-chart-container small">
                                                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                        <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                        <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="72,500" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    </svg>
                                                    <span className="circle-chart-percentage circle-chart-one-percentage">72%</span>
                                                </div>
                                                <div className="row circle-chart-content">JavaScript</div>
                                            </div>
                                            <div className="col-xs-3">
                                                <div className="circle-chart-container small">
                                                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                        <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                        <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="85,800" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    </svg>
                                                    <span className="circle-chart-percentage circle-chart-one-percentage">85%</span>
                                                </div>
                                                <div className="row circle-chart-content">ReactJS</div>
                                            </div>
                                            <div className="col-xs-3">
                                                <div className="circle-chart-container small">
                                                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                        <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                        <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="91,700" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    </svg>
                                                    <span className="circle-chart-percentage circle-chart-one-percentage">91%</span>
                                                </div>
                                                <div className="row circle-chart-content">
                                                    {/* <h3>Front-end</h3> */}
                                                    Graphic Design
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-xs-3">
                                                <div className="circle-chart-container small">
                                                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                        <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                        <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="72,900" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    </svg>
                                                    <span className="circle-chart-percentage circle-chart-one-percentage">72%</span>
                                                </div>
                                                <div className="row circle-chart-content">Adobe Enterprise</div>
                                            </div>
                                            <div className="col-xs-3">
                                                <div className="circle-chart-container small">
                                                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                        <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                        <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="11,200" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    </svg>
                                                    <span className="circle-chart-percentage circle-chart-one-percentage">11%</span>
                                                </div>
                                                <div className="row circle-chart-content">AI/ML</div>
                                            </div>
                                            <div className="col-xs-3">
                                                <div className="circle-chart-container small">
                                                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                        <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                        <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="25,300" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    </svg>
                                                    <span className="circle-chart-percentage circle-chart-one-percentage">25%</span>
                                                </div>
                                                <div className="row circle-chart-content">Back-end</div>
                                            </div>
                                            <div className="col-xs-3">
                                                <div className="circle-chart-container small">
                                                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">
                                                        <circle stroke="#64b2d1" strokeWidth="0.2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                        <circle className="circle-chart-circle" stroke="#64b2d1" strokeWidth="3" strokeDasharray="52,300" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                                                    </svg>
                                                    <span className="circle-chart-percentage circle-chart-one-percentage">52%</span>
                                                </div>
                                                <div className="row circle-chart-content">
                                                    {/* <h3>Code</h3> */}
                                                    Management
                                                </div>
                                            </div>
                                        </div>

                                        

                                       

                                        {/* <div className="bar-graph bar-graph-vertical bar-graph-two">
                                            <div className="bar-one bar-container">
                                                <div className="bar" data-percentage="40%"></div>
                                                <span className="year">2019</span>
                                            </div>
                                            <div className="bar-two bar-container">
                                                <div className="bar" data-percentage="55%"></div>
                                                <span className="year">2018</span>
                                            </div>
                                            <div className="bar-three bar-container">
                                                <div className="bar" data-percentage="68%"></div>
                                                <span className="year">2017</span>
                                            </div>
                                            <div className="bar-four bar-container">
                                                <div className="bar" data-percentage="82%"></div>
                                                <span className="year">2016</span>
                                            </div>
                                        </div> */}

                                    </div>
                                   
                                </section>

                               
                                    
                                <section id="section3" className="section3 portfolio" data-section-name="section3">
                                    
                                    <h2>Experience</h2>

                                    <div className="job-listing-container" onClick={()=>window.location='https://www.workday.com'}>
                                        <div className="row">
                                            <div className="col-md-3 col-xs-1 date">
                                                Oct 2020 <span></span> Now
                                            </div>
                                            <div className="col-xs-11 col-md-9 content">
                                                <h3>Principal, Software Engineer <FiArrowUpRight size={15}/></h3>
                                                <span className="company">Workday</span>
                                                <span className="location">Salt Lake City, UT</span>
                                                <div>Technical lead of several complex projects, leading a team of engineers in a variety of contexts; overseeing the design, implementation, timeline, and delivery.
                                                <p>Align the team with business goals, collaborating and helping them overcome technical challenges while maintaining high standards.</p>
                                                Ensure project transparency and effective communication with business stakeholders 
                                                <ul className="list">
                                                    <li>Ability to convey technical information to a wide variety of audiences</li>
                                                    <li><b>Optimize workflows</b>, developed tools, and architecture design to enable the developer team to deliver efficiently</li>
                                                    <li>Influenced design decisions to ensure smooth product delivery </li>
                                                    <li>Advocate for team growth, help and mentor a highly capable team </li>
                                                </ul>
                                                Understand business strategy and goals, translating into meaningful objectives and key results and execute strategic project roadmap.
                                                Drive success with minimal process and a heavy emphasis on individual responsibility.<br/>
                                                Support teams by helping them understand priorities and enabling them to ignore distractions and non-productive influences.
                                                </div>
                                                <div className="pill-container">
                                                    <span>JIRA</span>
                                                    <span>Confluence</span>
                                                    <span>AEM</span>
                                                    <span>WebSDK</span>
                                                    <span>Coveo</span>
                                                    <span>Planning</span>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                    <div className="job-listing-container" onClick={()=>window.location='https://www.tomeetapp.com'}>
                                        <div className="row">
                                            <div className="col-md-3 col-xs-1 date">
                                                Oct 2019 <span></span> Mar 2023
                                            </div>
                                            <div className="col-xs-11 col-md-9 content">
                                                <h3>CEO & Co-Founder <FiArrowUpRight size={15}/></h3>
                                                <span className="company">Meetapp</span>
                                                <span className="location">San Francisco, CA</span>
                                                <div>
                                                    Inspired and managed a team of 6 individuals to develop the core product.
                                                    Improved networking and presentation skills articulating the vision of the company to well-known investors and venture capital firms.
                                                    <p>Experience working closely with the CFO managing budgets and defining quarterly financial projections
                                                    Executing product feature development based on top priorities and user feedback</p>
                                                    Key contributor and architecting the development of core features of the product using React Native, Firestore, Tensorflow ML models, GCP, and cloud functions
                                                </div>
                                                <div className="pill-container">
                                                    <span>React Native</span>
                                                    <span>Flutter</span>
                                                    <span>GCP</span>
                                                    <span>AI/ML</span>
                                                    <span>TensorFlow</span>
                                                    <span>Firebase</span>
                                                    <span>Cloud functions</span>
                                                    <span className="green">Topic Modelling</span>
                                                    <span>SSO/SAML</span>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                    <div className="job-listing-container" onClick={()=>window.location='https://www.workday.com'}>
                                        <div className="row">
                                            <div className="col-md-3 col-xs-1 date">
                                                Jan 2016 <span></span> Oct 2020
                                            </div>
                                            <div className="col-xs-11 col-md-9 content">
                                                <h3>Senior, Software Engineer <FiArrowUpRight size={15}/></h3>
                                                <span className="company">Workday</span>
                                                <span className="location">Pleasanton, CA</span>
                                                <div>
                                                    Key contributor to several website redesigns and architecture for the Workday brand.
                                                    Developed complex UI components that are highly scalable in AEM using the Sling model, SPA editor, and Sightly.
                                                    <p>Increased team performance and efficiency using the scaffolding tool Yeoman to help the team accelerate product development.
                                                    Work closely with Product Owners and Business Analysts to improve the UX/UI of the navigation initiative.</p>
                                                    Implemented redesign forms in ReactJS and Marketo to improve MQLs and drive sales growth.
                                                    Drove the product demo project initiative in AngularJS to help sales easily present new workday product features.
                                                    <p>Lead developer for Elastic search implementation with ReactJS to power asset listing and allow custom filtering.
                                                    Architected the abstraction of the UI from the data layer using ReactJS custom hooks for  the customer stories initiative to efficiently enable our customer's search platform.</p>
                                                </div>
                                                <div className="pill-container">
                                                    <span>ReactJS</span>
                                                    <span>Javascript</span>
                                                    <span>AngularJS</span>
                                                    <span className="green">Accessibility</span>
                                                    <span>HTML</span>
                                                    <span>Yeoman</span>
                                                    <span>SASS</span>
                                                    <span>AEM</span>
                                                    <span className="green">SEO</span>
                                                    <span>XML</span>
                                                    <span>Java</span>
                                                    <span className="green">Analytics</span>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                    <div className="job-listing-container" onClick={()=>window.location='https://www.bayeast.org'}>
                                        <div className="row">
                                            <div className="col-md-3 col-xs-1 date">
                                                Sep 2013 <span></span> Dec 2015
                                            </div>
                                            <div className="col-xs-11 col-md-9 content">
                                                <h3>Software Engineer <FiArrowUpRight size={15}/></h3>
                                                <span className="company">Bay East Association of Realtors</span>
                                                <span className="location">Pleasanton, CA</span>
                                                <div>
                                                    Experience presenting technological concepts and project roadmap to the board of directors. 
                                                    Architected strategies for an optimal product launch with little to no downtime.
                                                    <p>Implemented ASP profile membership database using HTTP web services and JSON. 
                                                    Developed custom CMS using cutting-edge technologies such as WordPress, PHP, MySQL, JavaScript.
                                                    Created custom plugins and widgets to increase website functionalities and user engagement.</p>
                                                    Worked with AES, and DES encryption methods to allow web platform SSO and SAML compliance.
                                                    Implemented cross-browser and responsive web pages using popular frameworks.
                                                </div>
                                                <div className="pill-container">
                                                    <span>PHP</span>
                                                    <span>Javascript</span>
                                                    <span className="green">SAML Compliance</span>
                                                    <span>HTML & CSS</span>
                                                    <span>Wordpress CMS</span>
                                                    <span className="green">SEO</span>
                                                    <span>SQL</span>
                                                    <span>AES</span>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                </section>

                                <h2>Education</h2>
                                <span className="link-education"></span>
                                <div className="content">
                                    <div>Bachelor of Arts in <b>Media and Communication</b>, Fairfield, IA</div><br/>
                                    <div>Minor in <b>Business Administration</b>, Fairfield, IA</div>
                                </div>


                                </div>

                            </div>
                        </div>
                  

                
       </div>

       
               
    </>
    
  )
}
 
export default Resume;