import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import Resume from './pages/Resume';
import './css/app.scss';





function App() {

  let pathname = window.location.pathname.split('/')[1];
  
  return (

    <BrowserRouter>
      <div className={`App dark-theme ${pathname}`}>

        <div className="pages-container">
          <Routes className="page-wrap">
              <Route exact path="/" element={<Resume />} />
          </Routes>
          
        </div>

      </div>
    </BrowserRouter>

  );
}

export default App;